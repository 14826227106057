import { useEffect, useMemo, useState } from "react";
/**
 * useMediaMatch
 *
 * A react hook that signals whether or not a media query is matched.
 *
 * @param query The media query to signal on. Example, `"print"` will signal
 * `true` when previewing in print mode, and `false` otherwise.
 * @returns Whether or not the media query is currently matched.
 */
function useMediaMatch(query) {
    if (typeof window === "undefined") {
        console.warn("useMediaMatch cannot function as window is undefined.");
        return false;
    }
    var matchMedia = useMemo(function () { return window.matchMedia(query); }, [query]);
    var _a = useState(function () { return matchMedia.matches; }), matches = _a[0], setMatches = _a[1];
    useEffect(function () {
        setMatches(matchMedia.matches);
        var listener = function (event_) {
            return setMatches(event_.matches);
        };
        if (matchMedia.addEventListener) {
            matchMedia.addEventListener("change", listener);
            return function () { return matchMedia.removeEventListener("change", listener); };
        }
        else {
            matchMedia.addListener(listener);
            return function () { return matchMedia.removeListener(listener); };
        }
    }, [matchMedia]);
    return matches;
}
export { useMediaMatch };
