import v1 from './v1.js';
import v2 from './v2.js';
export const isHexString = (value) => {
    if (typeof value !== 'string' || !value.match(/^0x[0-9A-Fa-f]*$/)) {
        return false;
    }
    return true;
};
function walletConnect(options) {
    options.version = options.version || 2;
    return options.version === 2 ? v2(options) : v1(options);
}
export default walletConnect;
